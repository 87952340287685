var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FormulaBanner',{attrs:{"title":_vm.title,"formulaCode":_vm.formulaCode}}),_c('div',{staticClass:"index"},[_c('div',[_c('div',{staticClass:"top_nav"},[_c('span'),_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"formBody"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('MyPatient',{attrs:{"memberId":_vm.memberId,"selectValue":_vm.selectValue},on:{"change":_vm.userChange}}),_c('van-field',{attrs:{"rules":[
              { required: true, message: '请填写' },
              {
                pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                message: '请填写数字',
              } ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"titles"},[_c('span',{staticClass:"titlesName"},[_vm._v("踝部收缩压"),_c('i',[_vm._v("*")])]),_c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticClass:"information"},[_c('van-cell-group',{staticClass:"vanCell"},[_c('van-field',{attrs:{"placeholder":"请输入","input-align":"right"},model:{value:(_vm.foot),callback:function ($$v) {_vm.foot=$$v},expression:"foot"}})],1)],1),_c('div',{staticClass:"unit"},[_c('span',[_vm._v("mmHg")])])])])]},proxy:true}]),model:{value:(_vm.foot),callback:function ($$v) {_vm.foot=$$v},expression:"foot"}}),_c('van-field',{attrs:{"rules":[
              { required: true, message: '请填写' },
              {
                pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/,
                message: '请填写数字',
              } ]},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"titles"},[_c('span',{staticClass:"titlesName"},[_vm._v("双上肢收缩压高值"),_c('i',[_vm._v("*")])]),_c('span',{staticStyle:{"display":"flex"}},[_c('span',{staticClass:"information"},[_c('van-cell-group',{staticClass:"vanCell"},[_c('van-field',{attrs:{"placeholder":"请输入","input-align":"right"},model:{value:(_vm.finger),callback:function ($$v) {_vm.finger=$$v},expression:"finger"}})],1)],1),_c('div',{staticClass:"unit"},[_c('span',[_vm._v("mmHg")])])])])]},proxy:true}]),model:{value:(_vm.finger),callback:function ($$v) {_vm.finger=$$v},expression:"finger"}}),(_vm.compute === true)?_c('div',{staticStyle:{"margin":"16px","display":"flex","justify-content":"center"}},[_c('van-button',{staticClass:"formula-template-button",staticStyle:{"width":"90px"},attrs:{"block":"","type":"info","native-type":"submit"}},[_vm._v("提交")])],1):_vm._e()],1)],1)]),(_vm.show)?_c('CalculationResults',{attrs:{"countText":_vm.countTexText}}):_vm._e(),_c('FormulaExplain',{attrs:{"explain-data":_vm.StandardDescription}}),_c('FormulaReferences',{attrs:{"references-data":_vm.references}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }